.options-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  justify-items: center;
  margin-top: 10px;
}

.option {
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  user-select: none;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px; /* Adjust height as needed */ /* Ensure border is initially transparent */
}

.option.purple {
  border-color: purple;
}

.option.selected {
  background-color: rgb(
    189,
    174,
    223
  ); /* Light purple background for selected option */
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.support-plan-modal .modal-conent {
  width: 1000px;
}
