.refer-container {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Adjust the gap between rows as needed */
}

.refer-row {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust the gap between items in a row as needed */
}

.refer-row img {
  width: 50px; /* Adjust the image size as needed */
  height: auto;
}

.refer-row input[type="email"],
.refer-row input[type="text"] {
  border: 1px solid #0080b5;
  border-radius: 5px;
  width: 100%; /* Ensure inputs take up available space */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

/* Container for email input and error message */
.email-container {
  position: relative; /* Make the container relatively positioned */
  width: 100%; /* Ensure it spans the width of the container */
}

/* Error message styling */
.invalid-email {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.25rem;
  position: absolute; /* Position the error message absolutely within the email-container */
  bottom: -1.5em; /* Adjust this value to control the space between the email field and the error message */
  left: 0; /* Align to the left edge of the container */
  width: 100%; /* Ensure it spans the width of the container */
}

/* Additional button styles */
.submit-invite-button {
  padding: 1em 2em;
  border-radius: 5px;
  border: none;
  font-weight: bold;
  cursor: pointer;
}

.green-button {
  background-color: #16971b;
  color: white;
}

.white-button {
  background-color: white;
  color: #000;
  border: 1px solid #ccc;
}
