.custom-table { 
    border-collapse: separate; 
    border-spacing: 0 10px;
    border-bottom: #e4f3f7;
}

.table-header {
    border-radius: 12px;
    width: 100%;
    background-color: #e4f3f7;
    padding: 16px;
    border-bottom: #e4f3f7;
    text-align: left;
}

.table-header-name {
    text-align: left;
}

.table-row {
    background-color: #eff0ef;
    width: 100%;
    height: 28px;
}

td {
    padding: 10px;
    text-align: left;
}
td:first-child {
    border-left-style: solid;
    border-top-left-radius: 10px; 
    border-bottom-left-radius: 10px;
}
td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 10px; 
    border-top-right-radius: 10px; 
}

th:first-child {
    border-left-style: solid;
    border-top-left-radius: 10px; 
    border-bottom-left-radius: 10px;
}
th:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 10px; 
    border-top-right-radius: 10px; 
}

.icons {
    height: 10%;
    width: 10%;
}


/* .table-data {
    text-align: left;
} */