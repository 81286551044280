/* .card-detail {
    height: 140px;
    width: 180px;
    background-color: white;
    font-size: 12px;
    text-align: center;
    border-radius: 20px;
} */

.button-placer {

    bottom: 5%;
    right: 5%;
    float: right;
}

.results-buttons {
    text-align: right;
}

h4 {
    font-weight: 500;
    color: #56544a;
}

/* @media only screen and (max-width: 1400) {
    .button-placer {
      background-color: red;
    }
  } */


.results-custom-accordion .accordion-header {
    background-color: #5dbdd6 !important;
    color: white !important;
}

.results-custom-accordion .accordion-button {
    background-color: #5dbdd6 !important;
    color: white !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.results-custom-accordion .accordion-button:not(.collapsed) {
    background-color: #5dbdd6 !important;
    color: white !important;
}

.results-custom-accordion .accordion-button:focus {
    box-shadow: none !important;
}

.results-custom-accordion .accordion-body {
    border: 1px solid #5dbdd6 !important;
    background-color: #f8f9fa !important;
}