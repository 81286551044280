/* .recovery .title { 
	max-width: 60% !important; 
} */

.recovery > * { margin-bottom: 1vh; }
.recovery input {
	width: 5vw;
	height: 6vh;
	border: 2px solid #0080b5;
	border-radius: 5px;
}
.message {
	font-weight: 700;
	color: #941faa;
}
.recovery label {
	width: 200px;
	text-align: right;
	margin-right: 20px;
}