.results-card {
    width: 100%;
    max-width: 100%;
    /* height: 200px; */
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    color: #56544a;
    background-color: #e4f3f7;
    padding: 8px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.results-card-row {
    width: 104%;
}

.card-text {
    font-size: 16px;
}

.card-data {
    font-size: 516px;
}