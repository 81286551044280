/* .assessments-card-detail { */
    /* height: 100%;
    width: 70%;
    background-color: white; */
    /* margin: 2%; */
    /* font-size: 100%; */
    /* text-align: center;
    border-radius: 4vh !important;
    margin-bottom: 10%; */
/* } */
/* 
.row-position {
    margin-top: 7% !important;
    margin-left: 5% !important;
}  */

/* .class-card-img {
    height: 10vh;
    /* border-radius: 20px; */
    /* margin-bottom: 5%; */
/* }  */

/* .class-card-text {
    font-size: 24px;
    font-weight: 700;
    margin-top: 5%;
    color: #2c7693;
} */
/* 
.link {
    text-decoration: none;
    color: black;
    height: 100%;
    /* border-radius: 20px; */
/* } */ 

/* .grade-level {
    background-color: #5eb4cd;
    color: white;
    border-radius: 8px;
    align-items: center;
    text-align: center;
    margin-left: 20%;
    margin-right: 20%;
} */

.alpaca-message-positioner{
    margin-top: 10% !important;
}

.row-position-assessment {
    /* margin-top: 7% !important; */
    margin-left: 5% !important;
}  
