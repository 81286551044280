.modal-button {
  width: 150px;
  height: 60px;
  font-size: 1.2rem;
  margin: 5px;
  border: 2px solid #007bff;

  color: #000000;
  border-radius: 8px;
}

.modal-button.yes-button {
  background-color: #5eb4cd;
  order: 1;
}

.modal-button.no-button {
  order: 2;
  background-color: #ffffff;
}

.modal-body {
  text-align: center; /* Center-align modal body content */
}

.modal-title {
  text-align: center; /* Center-align modal body content */
}

.button-group {
  display: flex;
  justify-content: space-around; /* Space buttons evenly */
}

.modal-button.highlight {
  background-color: lightblue;
}
