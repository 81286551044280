
td {
    text-align: left;
}

.custom-table { 
    border-collapse: separate; 
    border-spacing: 0 10px;
}

.text-left {
    text-align: left;
}

.margin-left{
    margin-left: 3% !important;
}

/* tr {
    cursor: pointer;
} */

.assessment-status-header {
    font-weight: 700;
}

.assessment-icon {
    height: 25px;
    width: 25px;
}