.filter-container {
    display: flex;
    align-items: center;
    margin-bottom: 5%;
    /* justify-content: space-between;
    margin-bottom: 20px;  */
  }
  
  .filter-buttons {
    display: flex;
    gap: 10px;
    margin-right: 5%;
  }

  .filter-dropdown label {
    font-weight: bold;
    color: #56544a; /* You can adjust the color to your preference */
    margin-right: 5px; /* Add some spacing between the label and the dropdown */
  }
  
  /* Style for the dropdown */
  .filter-dropdown select {
    background-color: #e4f3f7;
    color: #2c7693;
    border: 1px solid #2c7693;
    padding: 5px;
    border-radius: 4px;
    outline: none;
  }
  
  /* Style for dropdown options */
  .filter-dropdown select option {
    background-color: #e4f3f7;
    color: #2c7693;
  }
  
  .light-blue-button-filter {
    padding: 1.6vh 2.6vw;
    border-radius: 8px;
    border: solid 1px #2c7693;
    border-bottom-style: solid;
    border-right-style: solid;
    font-weight: bold;
    background-color: #e4f3f7;
    color: #2c7693;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  /* Add styles for the active filter button */
  .light-blue-button-filter.active {
    background-color: #2c7693;
    color: #e4f3f7;
  }
  
  .light-blue-button-filter:active,
  .light-blue-button-filter:focus {
    background-color: #2c7693;
    color: #e4f3f7;
  }
  