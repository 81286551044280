Table td {
  text-align: center;
  /* border-bottom: 0.5px solid black; */
  font-weight: 600;
  /* background-color: white !important; */
  /* color: green; */
}

.header-th-left {
  text-align: left;
  /* padding: 20px; */
}

.task-score {
  vertical-align: middle;
}

.reading-score {
  vertical-align: middle;
}

.task-badge-parent span {
  text-align: center;
  margin: 0 auto;
  display: inline-block;
  width: 5vw;
}

.header-size-results {
  width: 15vh;
}

.align-left {
  text-align: left;
}

.align-centre {
  text-align: center;
}

.centre-student-items {
  vertical-align: middle;
}

.flag-size {
  vertical-align: middle;
  text-align: center;
  width: 4vh;
  /* width: 30%; */
}

.low-color {
  color: white;
  background-color: red !important;
  border: 1px solid red;
}

/* .med-color {
    color: white;
    background-color: orange !important;
    border: 1px solid orange;
} */

.med-color {
  background-color: rgb(217, 217, 48) !important;
  /* color: rgb(22, 151, 27, 1); */
  color: black;
  border: 1px solid rgb(217, 217, 48);
}

.noRisk-color {
  background-color: white !important;
  /* color: rgb(22, 151, 27, 1); */
  color: black;
  border: 1px solid rgb(22, 151, 27);
}

.header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sort-icon {
  width: 20px;
  /* height: 20px; */
  margin-top: 5px;
}

.hide-img {
  visibility: hidden;
}

.at-risk-filter-row {
  text-align: right;
}

.at-risk-filter #atRiskFilter {
  margin-right: 20px;
}

.at-risk-filter img.risk-bar {
  height: 20px;
}

.white-button {
  background-color: white;

  cursor: pointer;
}

.white-button:hover {
  background-color: #f0f0f0;
}

.circular-checkbox {
  width: 24px; /* Increase width */
  height: 24px; /* Increase height */
  border-radius: 50%; /* Ensures it appears as a circle */
  border: 1px solid #ccc;
  background-color: white;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
}

.circular-checkbox:checked {
  background-color: #007bff;
  border: 1px solid #007bff;
}
