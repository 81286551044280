.table-header {
    border-radius: 12px;
    width: 100%;
    background-color: #e4f3f7;
    font-weight: 700;
    height: 60px !important;
    margin-bottom: 16px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.accordion-button {
    background-color: #eff0ef !important;
}

.vertical-align-middle{
    vertical-align: middle;
}

.task-table-accordion-head > button {
    width: 100% !important;
    margin-bottom: 1vh;
    border-radius: 20px;
    height: 6vh;
    font-weight: 600;
}