/* @media print {
  @page {
    size: landscape;
  }

  .results-history-table thead {
    display: none !important;
  }

  .print-only-header {
    display: block !important; /* Or use display: table-row; */
/* }
} */

.printout-logo {
  background-image: url(../../public/assets/imgs/Alpaca-logo-white-outline.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100px;
  height: 100px;
  position: relative;
  top: -35px;
  z-index: 1;
}

.printout-page-description-header {
  color: #2c7693;
  font-weight: 700;
  font-size: 25px;
}

/* Page numbers in print */
@page {
  margin: 20mm;
  counter-increment: page;
}

.page-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-size: 12px;
}

.page-number:before {
  content: counter(page);
}

.total-pages:before {
  content: counter(pages);
}

/* To reset the counter on print */
body {
  counter-reset: page;
}

@media print {
  /* Ensure the footer is printed */
  .student-report-card {
    page-break-before: always;
    page-break-inside: avoid;
  }

  /* Ensure the first student report does not break on a new page */
  .student-report-card:first-of-type {
    page-break-before: auto;
  }

  /* Optionally hide any non-printable elements */
  .no-print {
    display: none !important;
  }

  /* Optional styling for page numbers or headers/footers */
  .page-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    font-size: 12px;
  }

  /* Add page numbering if needed */
  .page-number:before {
    content: "Page " counter(page);
  }
}
