.custom-table { 
    border-collapse: separate; 
    border-spacing: 0 10px;
    border-bottom: #e4f3f7;
}

.table-header {
    border-radius: 12px;
    width: 100%;
    background-color: #e4f3f7;
    padding: 16px;
    color: #56544a;
}

.table-row {
    background-color: #eff0ef;
    width: 100%;
    height: 28px;
}

td {
    padding: 10px;
}

.table-data {
    text-align: left;
    color: #56544a;
}

.header-th {
    text-align: center;
    padding: 20px;
}

.customCheckbox {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border-color: #2c7693;
}

input:checked ~ .customCheckmark {
    background-color: #2c7693;
  }

.select-all {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
}

.select-student-header {
    font-weight: 700;
    color: #56544a;
}

p {
    color: #56544a;
}

  .season-assessment-icon-small{
    /* margin-left: 5%; */
    height: 2vw;
    width: 2vw;
  }


  .no-students-message {
    border: 2px solid #007bff; /* Bootstrap primary color */
    border-radius: 8px;
    background-color: #f8f9fa; /* Bootstrap light gray */
    padding: 20px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .no-students-message h3 {
    color: #007bff;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .no-students-message p {
    color: #343a40; /* Bootstrap dark gray */
    font-size: 16px;
  }
  