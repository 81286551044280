.options-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  justify-items: center;
  margin-top: 10px;
}

.option {
  padding: 8px;
  margin: 4px;
  cursor: pointer;
  border: 2px solid transparent; /* Initially transparent border */
  background-color: white; /* White background */
}

.option.green {
  border-color: green; /* Border color for green options */
}

.option.yellow {
  border-color: yellow; /* Border color for yellow options */
}

.option.orange {
  border-color: rgb(241, 126, 32); /* Border color for yellow options */
}

.option.red {
  border-color: red; /* Border color for red options */
}

.option.selected.green {
  background-color: lightgreen; /* Highlight color for selected green options */
}

.option.selected.yellow {
  background-color: rgb(
    245,
    245,
    114
  ); /* Highlight color for selected yellow options */
}

.option.selected.orange {
  background-color: rgb(
    243,
    169,
    140
  ); /* Highlight color for selected yellow options */
}

.option.selected.red {
  background-color: lightcoral; /* Highlight color for selected red options */
}
