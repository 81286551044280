.dropdown {
    background-color: white;
    color: black;
    margin-left: 3%;
    /* width: 80%; */
}

.dropdown button {
    /* background-color: white;
    color: black;
    margin-left: 3%; */
    width: 100%;
}

.dropdown-toggle {
    white-space: normal !important;
}
  
  .custom-toggle {
    background-color: white;
    color: black;
    text-align: center;
  }
  
  .custom-menu {
    width: 100%;
  }

