.sandbox-row-position {
  margin-top: 7% !important;
  margin-left: 5% !important;
}

.custom-no-padding-top {
  padding-top: 0 !important;
}

.additional-row-position {
  margin-left: 5% !important;
}
