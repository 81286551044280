.hide-div{
    display: none;
}

.text-center {
    margin: 0 auto;
}

.printout-heading-fontsize {
  font-size: medium;
}


@media print {
  .pagebreak {
    padding-bottom: 30px;
    /* display: block; */
    /* page-break-after: always; */
    page-break-before: always;
  }
}

/* @page {
  size: auto;
  margin: 20mm;
} */