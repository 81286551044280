.page-description-header {
    color: #2c7693;
    font-weight: 700;
    /* width: 100%; */
}

p {
    font-weight: 500;
}

.vertical-border-line{
  border-right: 5px solid rgb(44, 118, 147, 0.25) !important;
}


