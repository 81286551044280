.hide-text {
    visibility: hidden;
}

/* .pending-tableContainer{
    background-color: rgb(173, 216, 230, 0.4);
    border-radius: 20px;
    border: 1px solid white;
    height: 10vh;
    /* padding: 0 1% 1% 1%; */
    /* overflow: auto; */
/* } */

.completed-tableContainer{
    background-color: rgb(144, 238, 144, 0.4);
    border-radius: 20px;
    border: 1px solid white;
    height: 10vh;
    /* padding: 0 1% 1% 1%; */
    overflow: auto;
}

.failed-tableContainer{
    background-color: rgb(144, 238, 144, 0.4);
    border-radius: 20px;
    border: 1px solid white;
    height: 10vh;
    /* padding: 0 1% 1% 1%; */
    overflow: auto;
}

.tables-container {
    height: 100%;
    overflow: auto;
}

/* .student-assessment-status thead {
    background-color: rgb(220, 220, 220);
    position: sticky;
    font-size: 100%;
    border-radius: 20px;
    border-bottom: solid black 0.5px;
    height: 5%;
    /* z-index: 9999; */
    /* top: 0;
    margin: 0 0 0 0;
    text-align: center; */
/* }  */



input[type="radio"] {
    margin-right: 0;
  }

.custom-table { 
    border-collapse: separate; 
    border-spacing: 0 10px;
    border-bottom: #e4f3f7;
}

td {
    padding: 10px;
}

td:first-child {
    border-left-style: solid;
    border-top-left-radius: 10px; 
    border-bottom-left-radius: 10px;
}

td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 10px; 
    border-top-right-radius: 10px; 
}

th:first-child {
    border-left-style: solid;
    border-top-left-radius: 10px; 
    border-bottom-left-radius: 10px;
}
th:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 10px; 
    border-top-right-radius: 10px; 
}

.x-icon {
    /* height: 25px;
    width: 25px; */
}

.table-data {
    text-align: left;
}

.header-th {
    text-align: center;
}

.header-th-left {
    text-align: left;
}

.pending-table-header {
    border-radius: 12px;
    width: 100%;
    background-color: #eff0ef;
    padding: 16px;
    border-bottom-width: 0px !important;
}

.pending-table-row {
    background-color: rgb(173, 216, 230, 0.4);
    width: 100%;
    height: 28px;
}

.completed-table-header {
    border-radius: 12px;
    width: 100%;
    background-color: #eff0ef;
    padding: 16px;
    border-bottom-width: 0px !important;
}

.completed-table-row {
    background-color: rgb(144, 238, 144, 0.4);
    width: 100%;
    height: 28px;
}

.failed-table-header {
    border-radius: 12px;
    width: 100%;
    background-color: #eff0ef;
    padding: 16px;
    border-bottom-width: 0px !important;
}

.failed-table-row {
    background-color: rgb(173, 216, 230, 0.4);
    width: 100%;
    height: 28px;
}