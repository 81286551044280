.form-detail {
  /* position: absolute; */
  margin-top: 10%;
  margin-left: 5%;
  /* left: 25%; */
  text-align: center;
  /* height: 50%; */
  width: 90%;
  background-color: white;
  padding: 10%;
  border-radius: 20px;
}

.form-detail input {
  border: 2px solid #0080b5;
  border-radius: 5px;
}

.form-detail select {
  border: 2px solid #0080b5;
  border-radius: 5px;
  width: 30vw;
  padding-top: 1vh;
  padding-bottom: 1vh;
  font-size: 2vh;
}

.form-detail-trial {
  /* position: absolute; */
  margin-top: 10%;
  margin-left: 5%;
  /* left: 25%; */
  text-align: center;
  height: 50%;
  width: 90%;
  background-color: #f9b540;
  padding: 10%;
  border-radius: 20px;
}

.form-detail-trial input {
  border: 2px solid #0080b5;
  border-radius: 5px;
}

.form-detail-trial select {
  border: 2px solid #0080b5;
  border-radius: 5px;
  width: 30vw;
  padding-top: 1vh;
  padding-bottom: 1vh;
  font-size: 2vh;
}

.form {
  position: unset;
}

.create-class-label {
  white-space: nowrap;
  font-weight: 600;
  font-size: 2vh !important;
  /* height: 2vh; */
}

.create-class-label-trial {
  white-space: nowrap;
  font-weight: 700;
  font-size: 2vh !important;
  color: white;

  /* height: 2vh; */
}

.create-class-label-specialed {
  white-space: nowrap;
  font-weight: 700;
  font-size: 2vh !important;
  color: white;

  /* height: 2vh; */
}

#form-input {
  border: solid 2px;
  border-color: #5dbdd6;
}
