.text-link {
	color: #007bff;
	text-decoration: underline;
	padding-right: 10px;
}
.expand .rec-circle {
	border: 3px solid black;
	margin: 0;
	padding: 0;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	text-align: center;
	font-size: 140%;
	line-height: 20px;
	font-weight: 500;
}
.interval {
	border: 2px solid #1ea689;
	padding: 10px;
}
/* .rec-header {
	padding: 3px 10px;
	background-color: #d4e8ef;
	margin-bottom: 10px;
} */
.rec-body { padding: 20px 0; }
.rec-body .description, .rec-body .video, .rec-body .files { margin-bottom: 30px;}
.files > thead { background-color: transparent; }
.low { background-color: white; color: #1ea689; }
.medium { background-color: #1ea68977; color: white; }
.high { background-color: #1ea689; color: white; }
tbody td { text-align: center; }
.recommendationsBody img {
	height: 100%;
	max-width: 60px;
	margin: 10px 0;
}
td { 
	/* border-right: 1px solid black;  */
}
td:last-child { border-right: 0; }