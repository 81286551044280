Table td {
    text-align: center;
    font-weight: 600;
}

/* .selected-task-score {
    /* width: 10%; */


.accordion-margin-zero {
    margin: 0;
    padding: 0;
}


.centre-item{
    vertical-align: middle;
}


.cursor-on{
    cursor: pointer;
}

.custom-table { 
    border-collapse: separate; 
    border-spacing: 0 10px;
    border-bottom: #e4f3f7;
}

.table-header {
    border-radius: 12px;
    width: 100%;
    background-color: #e4f3f7;
    padding: 16px;
}

.selected-results-table-row {
    background-color: #eff0ef;
    width: 100%;
    height: 6vh;
}


.task-results-table-row {
    width: 100% !important;
}

.selected-assessment-accordion-head > button {
    background-color: #d5d8d5 !important;
    width: 100% !important;
    margin-bottom: 1vh;
    border-radius: 20px;
    height: 5vh;
    font-weight: 600;
}


.go-right{
    float: right !important;
}

.plus-icon-size {
    width: 25%;
    height: 25%;
}

.header-vertical-top{
    vertical-align: top;
    width: 10%;
    /* max-width: 10%; */
}