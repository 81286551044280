.centered-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80%;
}

.input-button-group {
    display: flex;
    align-items: center;
}

.message-box {
    margin-top: 10px;
    padding: 10px;
    background-color: #f0f0f0;
    /* Light gray background */
    border-radius: 5px;
    width: 60%;
    text-align: left;
}

.error-box {
    background-color: #ffdada !important;
    /* Light red background for errors */
    border: 1px solid red !important;
}

.yellow-button {
    background-color: #fdd835; /* Golden yellow for more visibility */
    color: white;
    border-color: #fdd835;

    padding: 1.6vh 2.6vw;
    border-radius: 8px;
    margin: 0.8vh;
    border: solid 1px;
    border-bottom-style: solid;
    border-right-style: solid;
    font-weight: bold;

    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition for hover effect */

    /* Hover effect for better interactivity */
    &:hover {
        background-color: #ffc107; /* Slightly lighter shade on hover */
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24);
    }
}

.input-field-secret{
    height: 3em !important;
}
