.alpaca-message{
    /* padding-top: 6%;  */
    margin-left: 7%; 
    /* max-height: 50%; */
  }

.alpaca-message-icon {
    max-height: 50vh;
}

.alpaca-message-bubble {
    /* padding-top: 6%; */
    /* max-height: 50vh; */
    max-width: 30vw;
}

.speech-bubble-text-controller {
    position: relative;
    display: inline-block;
}

.speech-bubble-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    /* padding: 2%; */
    text-align: center;
    /* Optional: Set text color */
    font-size: 1.7vw;
}