.intro-vid-card-detail{
  min-height: 80%;
  width: 100%;
  background-color: white;
  /* margin: 2%; */
  /* font-size: 100%; */
  text-align: center;
  border-radius: 4vh !important;
  margin-bottom: 10%;
  margin-top: 10%;
  cursor: pointer;
}