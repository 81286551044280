.sandbox-card-text {
  font-size: 20px !important;
  font-weight: 700;
  margin-top: 5%;
  /* color: #2c7693; */
}

.sandbox-card-detail {
  min-height: 90%;
  width: 95%;
  background-color: white;
  /* margin: 2%; */
  /* font-size: 100%; */
  text-align: center;
  border-radius: 4vh !important;
  margin-bottom: 10%;
}
