.colleagues-overview-container{
    bottom: 0;
    right: 0;
    margin-left: 3%;
    margin-right: 3%;
    background-color: white !important;
    min-height: 70%;
    border-radius: 3vh;
    width: 95%;
}

/* Table Header Styling */
.my-colleagues-table th {
    background-color: #e4f3f7;
    color: #333;
    padding: 8px;
}

/* Active teacher row style */
.active-teacher {
    background-color: #d4edda; /* Light green for active teachers */
}

/* Pending teacher row style */
.pending-teacher {
    background-color: #f5f8d7; /* Light red for pending teachers */
}

/* Table Cell Styling */
.my-colleagues-table td {
    padding: 8px;
    border-bottom: 1px solid #ccc; /* Light grey border for separation */
}

/* Table Styling */
.my-colleagues-table {
    width: 100%;
    border-collapse: collapse;
}

/* Button styling */
.resend-invite-btn {
    background-color: #28a745; /* Bootstrap's success green for resend button */
    color: white;
    border: none;
    padding: 6px 12px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
}

.resend-invite-btn:hover {
    background-color: #218838; /* Darker green on hover */
}

.my-colleagues-table th,
.my-colleagues-table td {
    padding: 8px; /* Consistent padding */
    vertical-align: middle; /* Align text vertically for better readability */
}

/* Specific column width settings */
.my-colleagues-table th.name-col, .my-colleagues-table td.name-col {
    width: 25%; /* More space for the Name */
}

.my-colleagues-table th.role-col, .my-colleagues-table td.role-col {
    width: 20%; /* More space for the Role */
}

.my-colleagues-table th.status-col, .my-colleagues-table td.status-col {
    width: 10%; /* Less space for the Status */
}

.my-colleagues-table th.date-created-col, .my-colleagues-table td.date-created-col,
.my-colleagues-table th.last-invite-sent-col, .my-colleagues-table td.last-invite-sent-col {
    width: 15%; /* Adequate space for dates */
}

.my-colleagues-table th.action-col, .my-colleagues-table td.action-col {
    width: 10%; /* Minimal space, enough for the button */
}

/* Styling adjustments for the button within the table */
.resend-invite-btn {
    padding: 4px 8px; /* Smaller padding to make the button less bulky */
    font-size: 12px; /* Smaller font size for button text */
}

.d-inline-block {
    display: inline-block;
    width: 100%; 
  }
  