/* AdditionalColleaguesFunction.css */

/* Flex container to align items in a row */
.input-row {
  display: flex;
  align-items: center;
  gap: 1rem; /* Space between input fields horizontally */
  margin-bottom: 1rem; /* Space between rows vertically */
  flex-wrap: wrap; /* Ensure that fields wrap to the next line if necessary */
}

/* Style each input field */
.add-colleagues-form-fields {
  flex: 1; /* Allow inputs to grow and shrink as needed */
  margin: 0; /* Remove any default margin */
}

/* Ensure select field takes the full width of the column */
.select-field {
  flex: 1;
  margin: 0;
}

/* Additional styles for buttons if needed */
.remove-btn {
  margin-left: 1rem; /* Space between the fields and the remove button */
}

.share-class-button {
  background-color: #007bff; /* Light blue button color */
  color: #fff; /* White text color */
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.share-class-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}
