/* Ensuring the cards are equal in size */
.masterdash-card {
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  width: 100%; /* Ensures equal width for all cards */
  height: 150px; /* Equal height for all cards */
}

.masterdash-card:hover {
  transform: translateY(-5px);
}

.class-card-text {
  font-size: 1.1rem;
  font-weight: 600;
  color: #495057;
  margin-top: auto;
  margin-bottom: auto;
}

.link {
  text-decoration: none;
  color: inherit;
}

/* Adjusting the layout */
/* .container {
  display: flex;
  justify-content: center;
  align-items: center;
} */
/* 
.vh-100 {
  height: 100vh;
} */

/* h-100 {
  height: 100%;
} */

/* Centering the content */
/* .d-flex {
  display: flex;
  flex-direction: column;
} */

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}
