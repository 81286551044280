.students-list-table-container{
    background-color: rgb(220, 220, 220);
    border-radius: 20px;
    border: 2px solid black;
    /* height: 23vh; */
    padding: 0 1% 1% 1%;
    /* overflow: auto; */
}

.student-list-table .student-list-table-head{
    position: sticky;
    border-radius: 20px;
    border-bottom: solid black 0.5px;
    height: 5%;
    /* z-index: 9999; */
    background-color: rgb(220, 220, 220);
    top: 0;
    margin: 0 0 0 0;
}

.student-list-table-row{
    border-bottom: solid black 0.5px
}

.text-centre{
    margin: 5vh;
    text-align: center;
    font-weight: 700;

}

.text-left{
    text-align: left;
}

.action-button{
    /* width: 25px;
    height: 25px; */
    border: none;
    background-color: transparent;
}

.action-button img {
    width: 25px;
    height: 25px;
}