.option-additional {
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  user-select: none;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px; /* Adjust height as needed */
}

.option-additional.blue {
  border: 2px solid blue;
  background-color: white; /* Ensure no background color */
}

.option-additional.selected {
  background-color: rgba(
    40,
    171,
    211,
    0.836
  ); /* Light blue background for selected option */
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
