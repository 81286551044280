.observation-item {
  border: 1px solid;
  border-radius: 5px;
  margin: 10px 0;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

.green-border {
  border-color: green;
}

.yellow-border {
  border-color: yellow;
}

.light-orange-border {
  border-color: rgb(252, 176, 37);
}

.orange-border {
  border-color: rgb(252, 87, 37);
}

.red-border {
  border-color: red;
}

.observation-item.selected.green-border {
  background-color: lightgreen; /* Match the border color for green */
}

.observation-item.selected.yellow-border {
  background-color: rgb(252, 216, 37); /* Match the border color for yellow */
}

.observation-item.selected.light-orange-border {
  background-color: rgb(
    252,
    176,
    37
  ); /* Match the border color for light orange */
}

.observation-item.selected.orange-border {
  background-color: rgb(247, 102, 58); /* Match the border color for orange */
}

.observation-item.selected.red-border {
  background-color: rgb(231, 45, 45); /* Match the border color for red */
}
