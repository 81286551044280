/* IndividualStudentResultsOverview.css */

/* Custom styles for tabs */
.custom-tabs .nav-tabs {
  border-bottom: 2px solid #5dbdd6 !important;
}

.custom-tabs .nav-item.show .nav-link,
.custom-tabs .nav-link.active {
  color: #fff !important;
  background-color: #5dbdd6 !important;
  border-color: #5dbdd6 #5dbdd6 #fff !important;
}

.custom-tabs .nav-link {
  color: #5dbdd6 !important;
  font-weight: bold;
}

.custom-tabs .nav-link:hover {
  border-color: #5dbdd6 #5dbdd6 #e9ecef !important;
}

/* StudentsResultsTable.css */

.custom-tabs .nav-link {
  color: white !important;
  background-color: red !important;
  border-color: red !important;
}

.custom-tabs .nav-link.active {
  background-color: darkred !important;
  border-color: darkred !important;
}

.custom-tabs .nav-item {
  margin-bottom: -1px;
}

.custom-tabs .tab-content {
  padding: 16px;
  border: 1px solid red;
  border-top: none;
}
