/*
 * Importing Bootstrap styling in it's own 'cascade layer' to simplify 
 * how we override styling for Alpaca specific things.
 *
 * This is a relatively new feature of CSS that is only supported by
 * browser versions released in 2022
 * - Edge > 99
 * - Firefox > 97
 * - Chrome > 99
 * - Safari > 15.4
 */
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/utilities";

@import "bootstrap/dist/css/bootstrap.min.css" layer;

$utilities: map-merge(
  $utilities,
  (
    "height":
      map-merge(
        map-get($utilities, "height"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "height"), "values"),
              (
                10: 10%,
              )
            ),
        )
      ),
  )
);

@font-face {
  font-family: "Adia_Demo";
  src: local("Adia_Demo"), url("./fonts/Adia_Demo.ttf") format("truetype");
  font-weight: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  max-height: 100vh;
  background-color: white;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

hr {
  // margin-top: 2% !important;
  // margin-bottom: 2% !important;
  color: blue !important;
  border: 0;
  border-top: 3px solid rgb(44, 118, 147, 1) !important;
}

.page-description-bar {
  padding-top: 2%;
  min-height: 100px;
}

.blue-background {
  background-color: #8FCB88!important;
}

.button {
  padding: 1.6vh 2vw;
  border-radius: 8px;
  margin: 0.8vh;
  border: solid 1px;
  border-bottom-style: solid;
  border-right-style: solid;
  font-weight: bold;

  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.blue-button {
  @extend .button;
  background-color: #2c7693;
  color: white;
  border-color: #2c7693;
}

.light-blue-button {
  @extend .button;
  background-color: #e4f3f7;
  color: #2c7693;
  border-color: #2c7693;
}

.white-button {
  @extend .button;
  background-color: white;
  color: #2c7693;
  border-color: #2c7693;
}

.green-button {
  @extend .button;
  background-color: #16971b;
  color: white;
  border-color: #16971b;
}

.red-button {
  @extend .button;
  background-color: #971616;
  color: white;
  border-color: #971616;
}

.vertical-border {
  margin-top: 2% !important;
  // border-right: 3px solid rgb(44, 118, 147, 0.25) !important;
}

p {
  font-size: 92%;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.big-ball-spinner {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 12px solid #ffff;
  border-top-color: #ff4d00;
  animation: spin 1s infinite linear;
  z-index: 99999;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
