input[type="radio"] {
  margin-right: 0;
}

.cursor-on {
  cursor: pointer;
}

.custom-table {
  border-collapse: separate;
  border-spacing: 0 10px;
  border-bottom: #e4f3f7;
}

.table-header {
  border-radius: 12px;
  width: 100%;
  background-color: #e4f3f7;
  padding: 16px;
}

.table-row {
  background-color: #eff0ef;
  width: 100%;
  height: 28px;
}

td {
  padding: 10px;
}

td:first-child {
  border-left-style: solid;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

th:first-child {
  border-left-style: solid;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
th:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.x-icon {
  height: 2em;
  /* width: 25px; */
}

.table-data {
  text-align: left;
}

.header-th {
  text-align: center;
}

.spacing-left {
  margin-left: 20px;
}

.edit-students-cog {
  height: 2em;
}

.select-all-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.select-all-container input[type="checkbox"] {
  margin-top: 4px; /* Adjust the space between the text and the checkbox */
}
