.student-settings-container {
    /* position:absolute; */
    bottom: 0;
    right: 0;
    /* margin-right: 1%; */
    background-color: white !important;
    min-height: 90%;
    border-radius: 3vh;
    /* width: 70%; */
    /* margin-bottom: 5em; */
}

.student-settings-container-shape {
    margin-top: 5%;
    margin-left: 5%;
    width: 90%;
}