/* .headerContainer {
    /* position: fixed;
    width: 101%;
    top: 0px;
    z-index: 99999999; */
/* } */ 

.header {
    color: white;
    /* height: 50px; */
    height: 7vh;

    h1 {
        font-size: 5vh;
    }
}

.grey {
    background: #56544a !important;
}

.red {
    background: #d15755;
}

.orange {
    background: #e28052;
}

.yellow {
    background: #edbe42;
}

.green {
    background: #8ec35e;
}

.blue {
    // background: #0080b5;
    background: #1E7F43;
}

.purple {
    background: #b0639f;
}

.subheader {
    background: #56544a !important;
    color: white;

    height: 50px;
}

.logo {
    background-image: url(../../public/assets/imgs/Alpaca-logo-white-outline.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 150px;
    height: 150px;
    position: relative;
    /* right: 75px; */
    top: -35px;
    z-index: 1;
}

.subheading-text {
    line-height: 50px !important;
    /* z-index: 2; */
    
    /* Applying same positioning as logo so that it aligns correctly */
    /* position: relative; */
    /* left: 50px; */

    /* font-size: 120% !important; */
    font-size: 1.5vw !important;
}

.circle {
    width: 120px; 
    height: 120px;

    background: #56544a;
    border-radius: 120px;
    -webkit-border-radius: 120px;
	-moz-border-radius: 120px;

    position: relative;
    top: -35px;

    text-align: center;
}

.cloud {
    width: 120px; height: 120px;

    background: #56544a;
    border-radius: 120px;
    -webkit-border-radius: 120px;
	-moz-border-radius: 120px;

    position: relative;
    top: -35px;
    /*right: 100px;*/

    z-index: 1;
}


.cloud:after, .cloud:before {
    content: '';
    position: absolute;
    background: #56544a;
    top: 20px;
}

.cloud:before {
	left: 100px;

    width: 80px; height: 80px;
    border-radius: 80px;
	-webkit-border-radius: 80px;
	-moz-border-radius: 80px;
}

.cloud:after {
	right: 100px;

    width: 80px; height: 80px;
    border-radius: 80px;
	-webkit-border-radius: 80px;
	-moz-border-radius: 80px;
}

.leftPosition {
    position: absolute;
    top: 30px;
    left: -50px;
    width: 60px;
    z-index: 1;
}

.centrePosition {
    position: absolute;
    width: 80px;
    left: 20px;
    top: 20px;
}

.rightPosition {
    position: absolute;
    top: 30px;
    left: 110px;
    width: 60px;
}

@media only screen and (max-width: 1181px) {
    .subheader {
        height: 5vw;
    }

    .logo {
        background-size: auto 15vw;
        height: 15vw;
        top: -3.5vw;
    }

    .subheading-text {
        line-height: 5vw !important;
        /* font-size: 1.5vw !important; */
    }
    
    .cloud {
        width: 12vw; 
        height: 12vw;
    
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
    
        position: relative;
        top: -3.5vw;
    }
    
    
    .cloud:after, .cloud:before {
        top: 2vw;
    }
    
    .cloud:before {
        left: 10vw;
    
        width: 8vw; 
        height: 8vw;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
    }
    
    .cloud:after {
        right: 10vw;
    
        width: 8vw; 
        height: 8vw;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
    }
    
    .leftPosition {
        top: 3vw;
        left: -5vw;
        width: 6vw;
    }
    
    .centrePosition {
        width: 8vw;
        left: 2vw;
        top: 2vw;
        z-index: 2;
    }
    
    .rightPosition {
        top: 3vw;
        left: 11vw;
        width: 6vw;
    }
}