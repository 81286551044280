#Login {
    height: 100%;
}

button {
    margin-top: 20px;
    margin-bottom: 20px;
}

.btn-primary.disabled,
.btn-primary:disabled {

    background-color: #2c7694 !important;
    border-color: white !important;
    border-width: 5px !important;
    box-shadow: 5px 5px 5px grey !important;

    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
}

.btn-primary {

    background-color: #2c7694 !important;
    border-color: white !important;
    border-width: 5px !important;
    box-shadow: 5px 5px 5px grey !important;

    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
}

.login-form {
    position: absolute;
    top: 35%;
    left: 15vw;
    width: 45%;
}

.label {
    white-space: nowrap;
}

.set-right {
    position: sticky;
    margin-left: 70%;
}

.alert {
    margin-top: 20px;
}

#login-button {
    margin-top: 20px;
}

.custom-input-group .form-control {
    flex: 0 1 auto !important;
    /* Prevents the input from growing too wide */
    width: 25vw !important;
}

.custom-input-group .form-control {
    /* width: 30vw; */
    border: 2px solid #0080b5;
    border-radius: 1vh;
    font-size: 2vh;
    font-weight: 500;
}

.custom-input-group .input-group-text {
    background-color: white;
    /* Match the background of the input field */
    border: 2px solid #0080b5;
    border-left: none;
    /* Remove the left border to merge with the input field */
    border-radius: 0 1vh 1vh 0;
    /* Match the right-side border radius */
    font-size: 2vh;
    font-weight: 500;
    padding: 0 10px;
    /* Adjust padding for better sizing */
    cursor: pointer;
}

.custom-button-width {
    width: 70%; /* Adjust this to your desired width */
    margin: 0 auto; /* Center the button */
}


/* .login-button-override {
    background-color: #2c7693;
    color: white;
    border-color: #2c7693;

    padding: 1.6vh 2vw;
    border-radius: 8px;
    margin: 0.8vh;
    border: solid 1px;
    border-bottom-style: solid;
    border-right-style: solid;
    font-weight: bold;

    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
} */