.right-panel {
    position: absolute;
    right: 0;
    height: 100%;
    width: 35%;
    background-color: #4da6bf !important;
    /* z-index: -1; */
    /* padding-left: 10vw !important; */

    color: white;
    text-align: center;
}

.signup-msg{
    margin-bottom: 40%;
}


.button-padding {
    --bs-btn-padding-x: 2rem !important;
}