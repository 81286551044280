.normative-curve-container {
  position: relative;
  width: 100%;
  height: 300px; /* Adjust height as needed */
  margin-top: 50px; /* To provide space for the curve */
}

.bell-curve {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 150px;
  background: radial-gradient(
    circle at center,
    transparent 70%,
    #ccc 71%,
    #ccc 100%
  );
  border-radius: 50%;
  overflow: hidden;
}

.bell-curve::before {
  content: "";
  position: absolute;
  width: 200%;
  height: 100%;
  background: radial-gradient(circle at center, #ccc 70%, transparent 71%);
  left: -50%;
  top: 50%;
  transform: translateY(-50%);
}

.score-bar {
  position: absolute;
  bottom: 0; /* Align bars to the bottom */
  width: 5px; /* Adjust bar width as needed */
  height: 50px; /* Adjust bar height as needed */
}

.percentile-labels {
  position: absolute;
  bottom: -20px; /* Position labels below the bars */
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 12px; /* Adjust font size as needed */
}
