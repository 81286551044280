/* .text-center {
    margin: 0 auto;
} */

/* .qr-button-styling{
    padding: 5% 10% !important;
}

.qr-button-font{
    color: white;
    font-weight: 700;
    font-size: 1.3vh;
    margin-top: 5%;
    margin-bottom: 0%;
} */


.add-pupils-button {
    /* height: 80px; */
    /* font-size: 18px; */
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px;
    max-width: 100%;
}

.add-pupils-icon {
    width: 35%;
    height: auto;
}