.shareclass-button-placer {
  margin-left: 65%;
  margin-top: 2% !important;
  /* bottom: 5%; */
  /* left: 5%; */
  /* float: left; */
}

.add-colleagues-form-fields {
  width: 100% !important;
  height: 100% !important;
}

.colleague-icon {
  width: 3vw;
}

.table-responsive {
  max-height: 400px; /* Adjust as necessary */
  overflow-y: auto;
}

.table-responsive table {
  width: 100%;
  table-layout: auto;
}

.table-responsive th,
.table-responsive td {
  white-space: nowrap;
  overflow: hidden;
}

.modal-90w {
  max-width: 90% !important;
}

.modal-title-centered {
  width: 100%;
  text-align: center;
}
