.qr-card-detail {
    min-height: 90%;
    width: 70%;
    background-color: white;
    /* margin: 2%; */
    /* font-size: 100%; */
    text-align: center;
    border-radius: 4vh !important;
    margin-bottom: 10%;
}

.row-position {
    margin-top: 7% !important;
    margin-left: 5% !important;
} 

.qr-class-card-img {
    height: 45%;
    width: 45%;
    border-radius: 20px;
    margin-bottom: 5%;
    margin-left: 27.5%;
}

.qr-class-card-text {
    font-size: 24px;
    font-weight: 700;
    margin-top: 5%;
    color: #2c7693;
}

.grade-level {
    background-color: #5eb4cd;
    color: white;
    border-radius: 8px;
    align-items: center;
    text-align: center;
    margin-left: 20%;
    margin-right: 20%;
}
