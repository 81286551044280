.grade1-card-detail {
    border-radius: 2vh;
    border: 0.3vh solid #5eb4cd !important;
    width: 100% !important;
    height: 100% !important;
    background-color: #5eb4cd!important;
    font-size: 80%;
    text-align: center;
    vertical-align: middle;
    margin-top: 2%;
    margin-right: 0%;
}

.grade2-card-detail {
    border-radius: 2vh;
    border: 0.3vh solid #B662A6!important;
    width: 100% !important;
    height: 100% !important;
    background-color: #B662A6!important;
    font-size: 80%;
    text-align: center;
    vertical-align: middle;
    margin-top: 2%;
    margin-right: 0%;
}

.grade-text{
    font-weight: 700;
    color: white;
    margin: 0 auto;
    padding-top: 3%;
    padding-bottom: 3%;
    /* white-space: nowrap; */
}