.teacher-profile-container {
  margin: 5% 3%;
  background-color: white;
  min-height: auto; /* Let the height grow automatically */
  padding: 2%;
  border-radius: 3vh;
}

.profile-form-detail {
  margin-top: 5%;
  margin-left: 5%;
  width: 90%;
  background-color: white;
  border-radius: 20px;
  padding: 2%; /* Added padding */
  text-align: center;
}

.form-teacher-profile .checkbox-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px; /* Adjust gap as needed */
  width: 100%; /* Ensure it fits the container */
  text-align: left;
}

.form-teacher-profile .form-control {
  width: 60%;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"] {
  width: 30vw;
  border: 2px solid #0080b5;
  border-radius: 1vh;
  /* font-size: 3vh; */
  font-weight: 500;
}

.form-teacher-profile input {
  border: 2px solid #0080b5;
  border-radius: 5px;
}

.profile-page-label {
  font-weight: bold;
}

.profile-page-description-header {
  padding-top: 2%;
  margin-bottom: 2%;
  font-weight: 700;
}

/* Button Group Styling */
.button-group-edit-profile {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 15px;
}

.green-button {
  background-color: #0080b5;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.green-button:hover {
  background-color: #006494;
}
