.additional-colleague-button {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  width: 80%;
  margin-left: 15%;
}

.additional-colleague-card-detail {
  background-color: white;
  text-align: center;
  border-radius: 4vh !important;
  margin-bottom: 10%;
  padding: 20px; /* Add padding for better button appearance */
}

.assessment-reminder-card-img {
  width: 15%;
  /* margin-top: 5%;
  margin-bottom: 5%; */
  margin: 5%;
}

.assessment-reminder-card-img-ac {
  width: 12%;
  /* margin-top: 5%;
  margin-bottom: 5%; */
  margin: 5%;
}

.additional-colleague-link {
  width: 30vw;
  height: 10vh !important;
  text-decoration: none;
  border: 2px solid #0080b5;
  border-radius: 2vh;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;

  /* Optional: Add hover effect */
  /* &:hover {
      background-color: #0080b5;
      color: #fff !important;
    } */
}

.ass-heading-link {
  /* width: 30vw; */
  /* height: 10vh !important; */
  text-decoration: none;
  border: 2px solid #0080b5;
  border-radius: 2vh;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  /* cursor: pointer; */
  transition: background-color 0.3s ease;
  text-align: center;
}

.assessment-reminder-content {
  width: 50%;
}

.additional-colleague-content {
  margin-left: 20%;
  display: flex;
  align-items: center;
}

/* --------- */

.additional-colleague-button-shareclass {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  width: 80%;
  margin-left: 30%;
}

.additional-colleague-link-shareclass {
  width: 25vw;
  height: 10vh !important;
  text-decoration: none;
  border: 2px solid #0080b5;
  border-radius: 2vh;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;

  /* Optional: Add hover effect */
  /* &:hover {
      background-color: #0080b5;
      color: #fff !important;
    } */
}

.additional-colleague-content-shareclass {
  margin-left: 20%;
  display: flex;
  align-items: center;
}

.additional-colleague-card-img-shareclass {
  width: 20% !important;
  /* margin-top: 5%;
  margin-bottom: 5%; */
  margin: 5%;
}

.form-group {
  position: relative;
}

.form-control {
  padding-right: 2.5rem; /* Make space for the error message */
}

.invalid-feedback {
  position: absolute;
  top: 100%;

  font-size: 0.875em;
  color: #dc3545; /* Bootstrap error color */
  padding: 0.25rem 0;
  margin-top: 0.125rem;
  white-space: nowrap;
}


.intro-additional-coll-card-detail{
  min-height: 80%;
  width: 100%;
  background-color: white;
  /* margin: 2%; */
  /* font-size: 100%; */
  text-align: center;
  border-radius: 4vh !important;
  margin-bottom: 10%;
  margin-top: 10%;
  /* margin-right: 10% !important; */
  cursor: pointer;
}