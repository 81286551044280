.textarea-container {
  max-height: 20vh;
}

.full-size {
  height: 100%;
  width: 90%;
}

.form-positioning {
  position: relative;
  top: 0;
  left: 0;
}

.grey-bg {
  background-color: grey;
}

.textarea-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.textarea-container .csv-upload {
  margin-top: 10px; /* Adds space between the button and the CSV upload */
}

.uploadFile {
  width: 50%;
}
