.resultsTable {
    table-layout: fixed;
}

.task-results-table-head {
    display: contents;
    font-size: 20px;
    font-weight: 800;
}

tfoot  {
    font-size: 20px;
    font-weight: 800;
}