.text-center {
    margin: 0 auto;
}

.qr-button-styling{
    padding: 5% 10% !important;
}

.qr-button-font{
    color: white;
    font-weight: 700;
    font-size: 1.3vh;
    margin-top: 5%;
    margin-bottom: 0%;
}
