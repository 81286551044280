.left-panel {
    position: absolute;
    left: 0;
    height: 100%;
    width: 15%;
    background-color: white;
    /* z-index: -9999; */
}

.side-nav {
    margin-top: 10vh;
    width: 100%;
    padding: 20px;
  }
  
  .nav-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .nav-list li {
    margin-bottom: 5%;
  }
  
  .nav-list li a {
    text-decoration: none;
    color: #2c7693;
    font-weight: bold;
    font-size: 2.5vh;
    display: block;
    padding: 5%;
    transition: background-color 0.3s;
  }
  
  .nav-list li a:hover {
    background-color: #ddd;
  }
  
  /* Style for React Router Link */
  .nav-list li a:focus,
  .nav-list li a:active,
  .nav-list li a:hover {
    background-color: #ddd; /* Apply the same hover background on interaction */
    outline: none; /* Remove the default focus outline */
  }
  