.recommedations-button-styling{
padding: 5% 10% !important;
}

.recommedations-button-font{
    color: white;
    font-weight: 700;
    font-size: 1.3vh;
    margin-top: 5%;
    margin-bottom: 0% !important;
}