.admindash-card-detail{
    /* height: 90vh; */
    width: 90%;
    background-color: white;
    /* margin: 2%; */
    /* font-size: 100%; */
    text-align: center;
    border-radius: 4vh !important;
    margin-bottom: 10%;
}

.admindash-row-position {
    margin-top: 18% !important;
    margin-left: 5% !important;
}

.admin-class-card-img {
    height: 7vw;
    margin-top: 5%;
    margin-bottom: 5%;
}

/* .class-card-text {
    font-size: 24px;
    font-weight: 700;
    margin-top: 5%;
    color: #2c7693;
} */

.link {
    text-decoration: none;
    color: black;
    height: 100%;
}

.grade-level {
    background-color: #5eb4cd;
    color: white;
    border-radius: 8px;
    align-items: center;
    text-align: center;
    margin-left: 20%;
    margin-right: 20%;
}
