.pale-yellow-bg {
  background-color: #ffffcc;
}

.report-white-button {
  background-color: white;
  color: #2c7693;
  border-color: #2c7693;
}

.circular-button {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: blue;
}

.circular-button img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.circular-button .bi-pencil-square {
  font-size: 1.2em;
}

.white-button {
  display: inline-flex; /* Changed to inline-flex to respect width and height */
  align-items: center;
  justify-content: center;
  padding: 0; /* Remove any padding */
  border: none; /* Remove default border */
  background: none; /* Remove default background */
}

.white-button img {
  width: 15px;
  height: auto; /* Ensure images maintain aspect ratio */
}
