.table-container {
  margin: 20px;
}

.section {
  margin-bottom: 30px;
}

.performance-table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}

.performance-table th,
.performance-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.performance-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.performance-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.performance-table tr:hover {
  background-color: #e0e0e0;
}
