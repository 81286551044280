.class-overview-container {
  bottom: 0;
  right: 0;
  margin-left: 3%;
  margin-right: 3%;
  background-color: white !important;
  min-height: 70%;
  border-radius: 3vh;
}

.container-shape {
  margin-top: 5%;
  width: 90%;
}

.button-container {
  height: 100%;
}

.right-side-container {
  margin-top: 2% !important;
  height: 100%;
}

h3 {
  color: #56544a;
  font-weight: 700;
}

.class-overview-table-header {
  font-weight: 700;
  color: #56544a;
}

.centre-buttons-classoverview {
  margin-left: 3% !important;
}
/* .paragraph-font-size {
    font-size: 90%;
} */

.year-header {
  color: #2c7693;
  font-weight: 700;
  font-size: 2.5vh;
  /* width: 100%; */
}

/* CSS for customizing tabs */
.custom-tab {
  font-size: 1.25rem; /* Increase font size */
  padding: 0.75rem 1rem; /* Increase padding */
}

.custom-tab .nav-link {
  font-size: 1.25rem; /* Ensure font size is applied to nav-link */
  padding: 0.75rem 1rem; /* Ensure padding is applied to nav-link */
}

.custom-tab .nav-link.active {
  font-weight: bold; /* Make active tab more prominent */
}
