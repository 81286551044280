input[type=radio] {
  width: 18px;
  height: 18px;
}

.assessment-button {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
  min-width: 95% !important;
  min-height: 10vh;
  margin-top: 4vh !important;
  font-size: 1.12vw;
}

.assessment-button.selected {
  background-color: #2c7693;
  color: white;
}

/* Default styles for all assessment buttons */
.assessment-button {
  background-color: #f0f0f0;
  color: #333;
  border: 1px solid #ccc;
  padding: 10px 15px;
  margin: 5px;
  cursor: pointer;
}

/* Additional styling for button hover state */
/* .assessment-button:hover {
  background-color: #e0e0e0; 
} */

/* Styles for the Spring and Summer assessment buttons when disabled */
/* Styles for the Spring and Summer assessment buttons when disabled */
.assessment-button.disabled {
  background-color: #ccc; /* Greyed out background color */
  color: #999; /* Greyed out text color */
  cursor: not-allowed; /* Show not-allowed cursor */
}

/* Apply grayscale filter to the icon when its parent button is disabled */
.assessment-button.disabled .season-assessment-icon {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  opacity: 0.5;
}


.season-assessment-icon {
  margin-left: 5%;
  height: 9vh;
  width: 8vh;
}

.button-task-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.task-icon {
  /* margin-left: 5px; */
  width: 3vw;
}
