.senco-row td {
  font-size: 12px;
}

.senco-row th {
  font-size: 12px;
}

.senco-table-row td {
  font-size: 1vw;
}

.senco-table-row th {
  font-size: 1vw;
}

.item-list {
  padding: 5px 0; /* Adds space between items */
  border-bottom: 1px solid #eee; /* Adds a line between items */
}

.item-list:last-child {
  border-bottom: none; /* Removes the bottom border for the last item */
}

/* Existing styles that might be hiding elements */
.senco-hide-div {
  display: none;
  /* other styles that hide or alter visibility */
}


/* Print-specific styles */
@media print {
  .senco-landscape-print {
    width: 100%;
    overflow: visible; /* Ensure no content is clipped */
    @page {
      size: landscape !important;
    }
  }
  .senco-hide-div {
    display: block; /* Ensure it is displayed during print */
    visibility: visible; /* Override any visibility settings */
    position: static; /* Ensure it's not positioned out of flow */
    transform: none; /* Reset transformations that could hide it */
    max-height: none; /* Ensure full content is displayed */
  }

  .senco-row td {
    font-size: 20px;
  }
  
  .senco-row th {
    font-size: 20px;
  }
}
  
@media screen and (max-width: 1180px) {
  .senco-table-row td, 
  .senco-table-row th {
    font-size: 0.8vw;
  }
}


.senco-expandable-modal .modal-content{
  width: 1000px !important;
}